import postImage from "../../assets/images/postImage.png";
import ClockIcon from "../../assets/svgIcons/ClockIcon";
import ClockOutlineIcon from "../../assets/svgIcons/ClockOutlineIcon";
import FeedIcon from "../../assets/svgIcons/FeedIcon";
import TagIcon from "../../assets/svgIcons/TagIcon";
import TagOutlineIcon from "../../assets/svgIcons/TagOutlineIcon";
import ThreeDotsVerticalIcon from "../../assets/svgIcons/ThreeDotsVerticalIcon";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { timeSincePost } from "../../helperFunctions";
import api from "../../api";
import { io } from "socket.io-client";
import axios from "axios";
import { getAxiosConfig } from "../../endpoints/axios.config";
import { useSnackbar } from "notistack";
import Header from "../../global/components/Header";
import "../../assets/style/style.css";
const tabs = ["All", "Latest", "Trending"];

export const NewsFeedCard = ({ data, isNew }) => {
  // console.log("====================================");
  // console.log("THIS IS NEWS FEED ITEM", data);
  // console.log("====================================");
  const link = (data?.dataValues?.link || data?.link)
    ?.replace(/<!\[CDATA\[/g, "")
    ?.replace(/\]\]>/g, "");
  return (
    <Link
      to={link}
      target="_blank"
      rel="noopener noreferrer"
      className={`flex flex-col hover:no-underline hover:bg-nwhited rounded-lg py-1.5 border gap-0 hover:text-light-black`}
    >
      <div class="grid grid-cols-6 gap-0 h-30">
        <div class="py-1.5 pl-4 col-span-2 sm:col-span-1 flex items-center italic">
          <span className="font-montserrat text-xss">
            {timeSincePost(data?.dataValues?.pubDate || data?.pubDate)}
          </span>
        </div>
        <div class="py-1.5 mr-4 px-3 h-31 col-span-4 sm:col-span-5 rounded bg-[#F7F7F7] ">
          {" "}
          <span
            className="font-montserrat font-bold text-[13px]/[18px] text-[#212121] text-left"
            dangerouslySetInnerHTML={{
              __html: (data?.dataValues?.title || data?.title)
                ?.replace(/<!\[CDATA\[/g, "")
                ?.replace(/\]\]>/g, ""),
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-6 gap-0">
        <div className="p-0.5 col-span-2 sm:col-span-1 flex relative ">
          <div className="mt-2 col-2 px-8  font-montserrat text-xss mr-4 sm:mr-0 ">
            <ThreeDotsVerticalIcon className={"w-5 h-5 "} fill={"#2e2e2e"} />
          </div>
          <div className="mt-2 col py-1 pl-6 font-montserrat text-xss absolute right-2 sm:right-0 pr-0 md:pr-2">
            <FeedIcon className={"w-3 h-3"} fill="#6f6f6f" />
          </div>
        </div>
        <div className="mt-2 py-0.5 px-3 col-span-4 sm:col-span-5 flex justify-around flex-col sm:flex-row">
          <span className="w-full sm:w-6/12 font-montserrat text-[11px] sm:text-[12px]  text-[#2E2E2E]">
            {data?.source}
          </span>
          <div className="w-full sm:w-6/12 flex flex-wrap">
            {data?.tags?.slice(0, 3).map((tag, tagIndex) => {
              return (
                <span
                  key={tagIndex}
                  className="pr-1.5 font-montserrat italic text-[11px] sm:text-[12px] text-[#737373]"
                >
                  #{tag}
                </span>
              );
            })}
          </div>
        </div>
      </div>

      {/* {data?.tags?.length ? (
        <div className="flex items-center gap-1">
          <TagOutlineIcon className={"w-3 h-3"} fill={"#6f6f6f"} />
          <span className="font-montserrat text-xss">Tags: </span>
          <div className="flex items-center gap-3 flex-wrap">
            {data?.tags?.map((tag, tagIndex) => {
              return (
                <span className="font-montserrat font-medium text-xs italic text-[#AE82F6]">
                  #{tag?.tag} tag
                </span>
              );
            })}
          </div>
        </div>
      ) : null} */}
    </Link>
  );
};

const NewsFeed = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [newsList, setNewsList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [fetching, setFetching] = useState(false);
  const page = useRef(0);
  const [isNew, setIsNew] = useState(false);
  const handlePublishHide = (id, deletedAt) => {
    axios.delete(api.deleteFeed(id), getAxiosConfig()).then(() => {
      if (deletedAt) {
        enqueueSnackbar("Feed is published!", { variant: "success" });
        alert("Feed is published!");
      } else {
        enqueueSnackbar("Feed is hidden!", { variant: "error" });
        alert("Feed is hidden!");
      }
      setRefresh(!refresh);
    });
  };

  const clearFeeds = () => {
    setNewsList([]);
    page.current = 0;
    setFetching(false);
  };

  const getFeeds = () => {
    setFetching(true);
    const url = `${api.getFeeds("", true)}?page=${page.current + 1}`;

    axios
      .get(url, getAxiosConfig())
      .then((res) => {
        setIsNew(true);
        if (page.current === 0) {
          setNewsList(res.data); // Replace existing data
        } else {
          setNewsList((prev) => [...prev, ...res.data]); // Append data
        }
        page.current += 1;
        setFetching(false);
      })
      .catch((error) => {
        console.error("Failed to fetch feeds:", error);
        setFetching(false);
      });
  };

  const onScroll = () => {
    const scrolledTo = window.scrollY + window.innerHeight;
    const isReachBottom = document.body.scrollHeight - scrolledTo <= 100; // Adjust threshold as needed

    if (isReachBottom && !fetching) {
      getFeeds();
    }
  };

  useEffect(() => {
    const socket = io(api.socketServer);

    socket.on("connect", () => {
      clearFeeds(); // Clear data on reconnect
      socket.emit("messageFromClient", { data: "Hello, from the browser!" });
    });

    socket.on("messageFromServer", (data) => {
      if (data.data === "Welcome to the socket server!") {
        console.log("Connected to the socket server");
        clearFeeds(); // Clear data when receiving welcome message
        getFeeds();
      }
    });

    getFeeds();
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="flex flex-col rounded-lg bg-white shadow-nShadow">
      {/* Tab container */}
      <div className="flex items-center gap-12">
        {/* {
          tabs?.map((tab, tabIndex) => {
            return <button
            onClick={() => setActiveTab(tabIndex)}
            className="flex flex-col flex items-center justify-center">
            <span className={`font-montserrat font-semibold ${activeTab === tabIndex ? 'text-light-black' : 'text-[#BABABA]'} `}>{tab}</span>
            {
              activeTab === tabIndex ?
              <div className="w-[90%] bg-pink h-0.5"></div>
              :
              null
            }
          </button>
        })
      } */}
      </div>
      {/* List container */}
      <div className="mt-4 flex flex-col gap-2 items-center justify-center">
        {newsList?.map((item, itemIndex) => {
          return (
            <div className="flex w-full items-center justify-center ">
              <div className="w-11/12">
                <NewsFeedCard data={item} isNew={isNew} />
              </div>
              {/* <span
            onClick={() =>
            handlePublishHide(
            item.dataValues.id,
            item?.dataValues?.deletedAt
            )
          }
          className="cursor-pointer w-1/12 text-center pt-10 text-gray-500"
          >
          <a href="#">
            {item?.dataValues?.deletedAt ? "Publish" : "Hide"}
          </a>
        </span> */}
            </div>
          );
        })}
      </div>
      <div className="p-2">{fetching && "Loading More..."}</div>
    </div>
  );
};

export default NewsFeed;
